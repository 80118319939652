import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgLaptop = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path fillRule="evenodd" d="m22 17-2 2h4v2H0v-2h4l-2-2V5h20v12zM20 7H4v10h16V7z" />
    </SvgIcon>
);

export default SvgLaptop;
