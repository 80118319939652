import makeStyles from '@material-ui/core/styles/makeStyles';

const useContactStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 1,
    },
    title: {
        ...theme.props.boxTitle,
        color: theme.palette.primary.main,
        display: 'flex',
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    icon: {
        color: theme.palette.primary.main,
        height: theme.spacing(5),
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        width: theme.spacing(5),
    },
    copy: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    col: {
        display: 'inline-flex',
        flexDirection: 'column',
        marginBottom: 0,
        marginTop: 0,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        textAlign: 'left',
    },
    label: {
        marginBottom: theme.spacing(0.5),
    },
    link: {
        alignItems: 'center',
        display: 'inline-flex',
        marginBottom: 0,
        '& + $label': {
            marginTop: theme.spacing(0.5),
        },
    },
    linkText: {
        ...theme.typography.link,
        ...theme.typography.h5,
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default useContactStyles;
