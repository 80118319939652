import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgTimer = (props) => (
    <SvgIcon viewBox="0 0 14 14" {...props}>
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M2.8 0v4.2h.007l-.007.007L5.6 7 2.8 9.8l.007.007H2.8V14h8.4V9.807h-.007L11.2 9.8 8.4 7l2.8-2.793-.007-.007h.007V0H2.8zm1.4 3.85L7 6.65l2.8-2.8V1.4H4.2v2.45zm5.6 6.3v2.45H4.2v-2.45L7 7.35l2.8 2.8z"
        />
    </SvgIcon>
);

export default SvgTimer;
