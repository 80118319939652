import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgMachineArm = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M14.396 9.75v.23h-2.549V7.43H7.794a3.732 3.732 0 0 1-.523.97l6.335 11.776h4.614V24H1.65v-3.824h3.926L2.262 9.445C1.141 8.769.376 7.558.376 6.156A3.819 3.819 0 0 1 4.2 2.332c1.657 0 3.059 1.071 3.594 2.55h4.053v-2.55h2.55v.23L16.957 0l6.666 3.11-.803 1.733-5.455-2.549-2.97 2.97v1.784l2.97 2.97 5.455-2.55.803 1.734-6.666 3.11-2.562-2.562zM2.926 6.156c0 .701.573 1.275 1.274 1.275s1.274-.574 1.274-1.275c0-.7-.573-1.274-1.274-1.274-.701 0-1.275.573-1.275 1.274z"
        />
    </SvgIcon>
);

export default SvgMachineArm;
