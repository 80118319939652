import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgPaperPlane = (props) => (
    <SvgIcon viewBox="0 0 602 604" {...props}>
        <path
            fillRule="evenodd"
            d="M150.5 553.667 0 0l602 302-180.976 44.142-262.322-181.88 195.7 207.223L150.5 553.667zM376.25 604l-81.721-111.513 81.721-73.009V604z"
        />
    </SvgIcon>
);

export default SvgPaperPlane;
