import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgPublication = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <g fillRule="evenodd">
            <path d="M18 2.727c1.276 0 2.607.164 3.818.546.818.272 1.527.6 2.182 1.09v16.91c-.655-.491-1.364-.818-2.182-1.091-1.21-.382-2.542-.546-3.818-.546-2.127 0-4.418.437-6 1.637-1.582-1.2-3.873-1.637-6-1.637s-4.418.437-6 1.637V4.363c1.582-1.2 3.873-1.636 6-1.636s4.418.437 6 1.637c1.582-1.2 3.873-1.637 6-1.637zm0 14.728c1.31 0 2.618.163 3.818.545V5.455c-1.2-.382-2.509-.546-3.818-.546-1.855 0-4.527.71-6 1.636v12.546c1.473-.927 4.145-1.636 6-1.636z" />
            <path d="M18 9.273c.96 0 1.887.098 2.727.283V7.898A14.73 14.73 0 0 0 18 7.636c-1.855 0-3.535.317-4.91.906v1.81c1.234-.697 2.946-1.08 4.91-1.08zm-4.91 2.17v1.812c1.234-.699 2.946-1.08 4.91-1.08.96 0 1.887.098 2.727.283V10.8A14.73 14.73 0 0 0 18 10.538c-1.855 0-3.535.327-4.91.906zm0 2.913c1.375-.589 3.055-.905 4.91-.905.938 0 1.865.087 2.727.262v1.658A12.757 12.757 0 0 0 18 15.087c-1.964 0-3.676.382-4.91 1.08v-1.81z" />
        </g>
    </SvgIcon>
);

export default SvgPublication;
