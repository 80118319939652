import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// MUI COMPONENTS
import MuiIconButton from '@material-ui/core/IconButton';

// STYLES
import useButtonIconStyles from '../../theme/elements/buttonIcon.style';

const ButtonIcon = (props) => {
    const {
        alignSuper,
        ariaControls,
        ariaExpanded,
        ariaLabel,
        children,
        customClasses,
        disabled,
        onClick,
        title,
        ...otherProps
    } = props;
    const classes = useButtonIconStyles();

    return (
        <MuiIconButton
            aria-controls={ariaControls}
            aria-expanded={ariaExpanded}
            aria-label={ariaLabel || title}
            className={cx(
                classes.root,
                alignSuper ? classes.alignSuper : '',
                disabled ? classes.disabled : '',
                customClasses
            )}
            disableFocusRipple
            disableRipple
            onClick={onClick}
            title={title}
            {...otherProps}
        >
            {children}
        </MuiIconButton>
    );
};

ButtonIcon.propTypes = {
    alignSuper: PropTypes.bool,
    ariaControls: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    ariaLabel: PropTypes.string,
    children: PropTypes.node,
    customClasses: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
};

ButtonIcon.defaultProps = {
    alignSuper: false,
    ariaControls: '',
    ariaExpanded: false,
    ariaLabel: '',
    children: null,
    customClasses: null,
    disabled: false,
    onClick: () => null,
    title: '',
};

export default ButtonIcon;
