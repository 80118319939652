import { alpha } from '@material-ui/core/styles/colorManipulator';

// eslint-disable-next-line import/prefer-default-export
export const addModuleRichtext = (themeInput) => {
    const theme = { ...themeInput };

    theme.props.richtext = {
        '& p:first-child': {
            marginTop: 0,
        },
        '& svg': {
            display: 'inline-block',
            verticalAlign: 'text-bottom',
        },
        '& p': {
            wordBreak: 'break-word',
            [theme.breakpoints.up('lg')]: {
                marginBlockEnd: `${theme.spacing(3)}px`,
                marginBlockStart: `${theme.spacing(3)}px`,
            },
        },
        '& p strong, & li strong, & strong': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '& p a, & li a, & a': {
            borderBottom: `1px solid ${theme.palette.action.hover}`,
            boxShadow: `inset 0 0 0 0 ${theme.palette.action.hover}`,
            color: 'inherit',
            paddingBottom: 1,
            textDecoration: 'none',
            transition: theme.transitions.create(['border-bottom-color', 'box-shadow'], {
                duration: theme.transitions.duration.shorter,
            }),
        },
        '& p a:focus, & li:focus, & a:focus': {
            borderBottomColor: alpha(
                theme.palette.action.focus,
                theme.palette.action.focusOpacity
            ),
            boxShadow: `inset 0 calc(-1em - 4px) 0 0 ${alpha(
                theme.palette.action.focus,
                theme.palette.action.focusOpacity
            )}`,
        },
        '& p a:hover, & li:focus, & a:hover': {
            borderBottomColor: alpha(
                theme.palette.action.hover,
                theme.palette.action.hoverOpacity
            ),
            boxShadow: `inset 0 calc(-1em - 4px) 0 0 ${alpha(
                theme.palette.action.hover,
                theme.palette.action.hoverOpacity
            )}`,
        },
        '& ul': {
            listStyle: 'none',
            marginBlockEnd: `${theme.spacing(3)}px`,
            marginBlockStart: `${theme.spacing(3)}px`,
            [theme.breakpoints.up('lg')]: {
                marginBlockEnd: `${theme.spacing(4)}px`,
                marginBlockStart: `${theme.spacing(4)}px`,
                paddingLeft: theme.spacing(7),
            },
        },
        '& ol': {
            counterReset: 'counter',
            listStyle: 'none',
            marginBlockEnd: `${theme.spacing(3)}px`,
            marginBlockStart: `${theme.spacing(3)}px`,
            [theme.breakpoints.up('lg')]: {
                marginBlockEnd: `${theme.spacing(4)}px`,
                marginBlockStart: `${theme.spacing(4)}px`,
                paddingLeft: theme.spacing(7),
            },
        },
        '& li': {
            counterIncrement: 'counter',
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(2),
            },
        },
        '& ul > li::before': {
            color: theme.palette.secondary.main,
            content: '"∘"',
            display: 'inline-block',
            fontWeight: theme.typography.fontWeightMedium,
            marginLeft: theme.spacing(-3),
            width: theme.spacing(3),
            [theme.breakpoints.up('lg')]: {
                marginLeft: theme.spacing(-4),
                width: theme.spacing(4),
            },
        },
        '& ol > li::before': {
            color: theme.palette.secondary.main,
            content: 'counter(counter)',
            display: 'inline-block',
            fontFamily: theme.typography.fontFamilyPoppins,
            fontWeight: theme.typography.fontWeightBold,
            marginLeft: theme.spacing(-5),
            textAlign: 'center',
            width: theme.spacing(5),
            [theme.breakpoints.up('lg')]: {
                marginLeft: theme.spacing(-7),
                width: theme.spacing(7),
            },
        },
    };

    return theme;
};
