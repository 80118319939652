import makeStyles from '@material-ui/core/styles/makeStyles';

const useHeaderStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(1),
        position: 'relative',
        transition: `padding ${theme.transitions.duration.standard}ms`,
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
        },
    },
    appbar: {
        boxShadow: theme.props.shadows.header,
        opacity: 1,
        transition: `z-index ${theme.transitions.duration.shorter}ms ease-in-out, transform ${theme.transitions.duration.shorter}ms ease-in-out, opacity ${theme.transitions.duration.shortest}ms ease-in-out ${theme.transitions.duration.shortest}ms`,
        '&.hide:not(.active)': {
            opacity: 0,
            transform: 'translateY(-30px)',
            zIndex: -1,
        },
        '&.active': {
            transform: 'none',
            transition: `none`,
        },
    },
    toolbar: {
        minHeight: 'auto',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        transition: `padding ${theme.transitions.duration.standard}ms ease-out`,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
        },
    },
    container: {
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'space-between',
    },
    '@keyframes blinker': {
        '0%': {
            opacity: 0,
        },
        '15%': {
            opacity: 1,
        },
        '70%': {
            opacity: 1,
        },
        '80%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },
    },
    logoLink: {
        '&:hover $logo .path-blue, &:focus $logo .path-blue': {
            animationDuration: '1200ms',
            animationIterationCount: 'infinite',
            animationName: '$blinker',
            animationTimingFunction: 'linear',
            opacity: 0,
        },
        '&:hover $logo .path-seafoam, &:focus $logo .path-seafoam': {
            animationDelay: '200ms',
            animationDuration: '1200ms',
            animationIterationCount: 'infinite',
            animationName: '$blinker',
            animationTimingFunction: 'linear',
            opacity: 0,
        },
        '&:hover $logo .path-flag, &:focus $logo .path-flag': {
            animationDelay: '400ms',
            animationDuration: '1200ms',
            animationIterationCount: 'infinite',
            animationName: '$blinker',
            animationTimingFunction: 'linear',
            opacity: 0,
        },
    },
    logo: {
        ...theme.props.logo,
        display: 'block',
        transition: `height ${theme.transitions.duration.standard}ms ease-out`,
        width: 'auto',
    },
    title: {
        ...theme.typography.gradient,
        alignItems: 'center',
        display: 'inline-flex',
        marginBottom: theme.spacing(5),
        minHeight: theme.spacing(6),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            marginLeft: theme.spacing(2),
            minHeight: theme.spacing(8),
            paddingRight: theme.spacing(7),
            paddingTop: 0,
        },
        [theme.breakpoints.up('md')]: {
            minHeight: theme.spacing(9),
            paddingRight: 0,
        },
    },
    html: {
        [theme.breakpoints.down('xs')]: {
            overflow: 'hidden',
        },
    },
    menu: {
        ...theme.props.logo,
        zIndex: theme.zIndex.modal + 1,
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            right: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            right: theme.spacing(2),
        },
    },
    toggle: {
        ...theme.props.buttonIconOnly,
        marginLeft: theme.spacing(3),
    },
    toggleIcon: {
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
}));

export default useHeaderStyles;
