import makeStyles from '@material-ui/core/styles/makeStyles';

const useQuickLinksStyles = makeStyles((theme) => ({
    wrap: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 0,
        '& ul, & li': {
            margin: 0,
            padding: 0,
            textIndent: 0,
            listStyleType: 'none',
        },
        '& li': {
            marginTop: theme.spacing(3),
            width: `calc(50% - ${theme.spacing(1.5)}px)`,
            [theme.breakpoints.between('751', '960')]: {
                marginLeft: theme.spacing(2),
                marginTop: 0,
                width: `calc(25% - ${theme.spacing(1.5)}px)`,
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.spacing(2),
                marginTop: 0,
                width: `calc(25% - ${theme.spacing(1.5)}px)`,
            },
        },
        [theme.breakpoints.down('751')]: {
            '& li:nth-child(-n+2)': {
                marginTop: 0,
            },
            '& li:nth-child(2n)': {
                marginLeft: theme.spacing(2),
            },
            '& li:nth-child(n+5)': {
                marginTop: theme.spacing(3),
            },
        },
        [theme.breakpoints.between('751', '960')]: {
            '& li:nth-child(4n+1)': {
                marginLeft: 0,
            },
            '& li:nth-child(n+5)': {
                marginTop: theme.spacing(3),
            },
        },
        [theme.breakpoints.up('md')]: {
            '& li:nth-child(4n+1)': {
                marginLeft: 0,
            },
            '& li:nth-child(n+5)': {
                marginTop: theme.spacing(3),
            },
        },
    },
    square: {
        ...theme.props.box,
        alignItems: 'center',
        background: `linear-gradient(-26deg, ${theme.palette.gradient.light} 0%, ${theme.palette.gradient.light} 30%, ${theme.palette.common.white} 60%, ${theme.palette.common.white} 100%)`,
        backgroundPosition: '0% 0%',
        backgroundSize: '300% 300%',
        color: theme.palette.gradient.medium,
        height: theme.spacing(10),
        justifyContent: 'center',
        maxHeight: theme.spacing(10),
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'center',
        transform: 'translateY(0)',
        transition: `background ${theme.transitions.duration.short}ms, background-color ${theme.transitions.duration.short}ms, box-shadow ${theme.transitions.duration.short}ms, color ${theme.transitions.duration.short}ms, transform ${theme.transitions.duration.shorter}ms`,
        [theme.breakpoints.between('751', '960')]: {
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(3),
        },
        '&:hover, &:focus': {
            backgroundPosition: '100% 100%',
            boxShadow: theme.props.shadows.boxActive,
            transform: 'translateY(-4px)',
        },
        '&:hover $squareText, &:focus $squareText': {
            color: theme.palette.common.white,
        },
    },
    squareText: {
        position: 'relative',
        transition: `color ${theme.transitions.duration.short}ms`,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 26 / 14,
        [theme.breakpoints.between('751', '960')]: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: 26 / 18,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: 26 / 18,
        },
    },
    icon: {
        display: 'block',
        height: theme.spacing(10),
        left: `-${theme.spacing(2)}px`,
        opacity: 0.1,
        position: 'absolute',
        top: `-${theme.spacing(3)}px`,
        width: theme.spacing(10),
    },
    copy: {
        fontWeight: theme.typography.fontWeightRegular,
        justifyContent: 'center',
        marginTop: theme.spacing(10),
        textAlign: 'center',
    },
    link: {
        alignItems: 'center',
        display: 'inline-flex',
        position: 'relative',
        '&:hover > $linkIcon, &:focus > $linkIcon': {
            color: theme.palette.secondary.main,
        },
    },
    linkText: {
        ...theme.typography.link,
        ...theme.typography.h5,
    },
    linkIcon: {
        height: theme.spacing(4),
        marginLeft: theme.spacing(0.5),
        transform: 'rotate(180deg)',
        transition: `color ${theme.transitions.duration.shorter}ms`,
        width: theme.spacing(4),
    },
}));

export default useQuickLinksStyles;
