import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// SVGS
import SvgLifesaver from '../svgs/Lifesaver';

// STYLES
import useContactStyles from '../../theme/dashboard/contact.style';

const Contact = (props) => {
    const { customClasses } = props;
    const classes = useContactStyles();

    return (
        <div className={cx(classes.root, customClasses)}>
            <Typography className={classes.title} variant="h3" component="div" align="center">
                <span>Need Help?</span>
                <SvgLifesaver role="presentation" className={classes.icon} />
            </Typography>
            <Typography className={classes.copy} variant="p" component="div" align="center">
                <p className={classes.col}>
                    <span>
                        <b>Australian Synchroton</b>
                    </span>
                    <span className={classes.label}>
                        <b>User Office</b>
                    </span>
                    <span className={classes.label}>
                        <b>Open Hours</b>
                    </span>
                    <span>Mon to Fri,</span>
                    <span>8:30AM - 4:30PM AEST</span>
                </p>
                <p className={classes.col}>
                    <span className={classes.label}>
                        <b>Phone Number</b>
                    </span>
                    <Link className={classes.link} underline="none" href="tel:+61385404217">
                        <span className={classes.linkText}>+61 3 8540 4217</span>
                    </Link>
                    <span className={classes.label}>
                        <b>Email</b>
                    </span>
                    <Link
                        className={classes.link}
                        underline="none"
                        href="mailto:user.office.vic@ansto.gov.au"
                    >
                        <span className={classes.linkText}>user.office.vic@ansto.gov.au</span>
                    </Link>
                </p>
            </Typography>
        </div>
    );
};

Contact.propTypes = {
    customClasses: PropTypes.string,
};

Contact.defaultProps = {
    customClasses: null,
};

export default Contact;
