import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

// FUNCTIONS
import { env } from '../../config';

// STYLES
import useAccountStyles from '../../theme/header/account.style';

const Account = (props) => {
    const { user, open } = props;
    const classes = useAccountStyles();

    const getDisplayName = () => (user.preferred_name ? user.preferred_name : user.first_names);

    return (
        <Box
            className={cx(classes.account, { active: open })}
            alignItems="center"
            display="flex"
            justifyContent="right"
        >
            <Link
                href={`${env.REACT_APP_ACCOUNT_USER_UI_URL}`}
                className={classes.name}
                underline="none"
                variant="body1"
            >
                <span className={cx(classes.link, { active: open })}>
                    Hello, {getDisplayName()}
                </span>
                {user.avatar_url && (
                    <div className={classes.avatar}>
                        <img
                            src={user.avatar_url}
                            role="presentation"
                            aria-hidden="true"
                            alt={`Profile image for ${getDisplayName()}`}
                        />
                    </div>
                )}
            </Link>
            <span className={cx(classes.separator, { active: open })} />
            <Link href={`${env.REACT_APP_SSO_UI_URL}/logout`} underline="none" variant="body1">
                <span className={cx(classes.link, { active: open })}>Log out</span>
            </Link>
        </Box>
    );
};

Account.propTypes = {
    // Whether the nav is open
    open: PropTypes.bool,
    // User object
    user: PropTypes.instanceOf(Object),
};

Account.defaultProps = {
    open: false,
    user: null,
};

export default Account;
