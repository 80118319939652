import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgLink = (props) => (
    <SvgIcon viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            d="m12.422 8.88 2.336-2.336a3.716 3.716 0 0 0 .002-5.24l-.062-.062a3.717 3.717 0 0 0-5.242 0l-3.34 3.34a3.717 3.717 0 0 0 0 5.242l.062.06c.12.12.25.228.379.329L7.779 8.99a2.082 2.082 0 0 1-.4-.307l-.062-.06a2.012 2.012 0 0 1 0-2.84l3.34-3.34a2.008 2.008 0 0 1 2.837 0l.063.063a2.01 2.01 0 0 1 0 2.838l-1.509 1.51a5.17 5.17 0 0 1 .374 2.026zm-2.537 2.54a3.72 3.72 0 0 0 0-5.242l-.063-.061a3.638 3.638 0 0 0-.379-.328L8.221 7.01c.144.083.278.186.4.307l.064.061a2.01 2.01 0 0 1 0 2.839l-3.341 3.339a2.01 2.01 0 0 1-2.838 0l-.063-.063a2.01 2.01 0 0 1 0-2.838l1.51-1.509a5.176 5.176 0 0 1-.374-2.025L1.242 9.456a3.717 3.717 0 0 0 0 5.242l.062.062a3.717 3.717 0 0 0 5.242 0l3.339-3.34z"
        />
    </SvgIcon>
);

export default SvgLink;
