import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgArrow = (props) => (
    <SvgIcon viewBox="0 0 32 32" {...props}>
        <path d="M23.41 6.82 20.59 4l-12 12 12 11.999 2.82-2.82-9.16-9.18 9.16-9.18z" />
    </SvgIcon>
);

export default SvgArrow;
