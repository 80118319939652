import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgShield = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M2.4 3.6 12 0l9.6 3.6v7.308c0 6.06-4.092 11.712-9.6 13.092-5.508-1.38-9.6-7.032-9.6-13.092V3.6zm4.8 9.6h3v3h3.6v-3h3V9.6h-3v-3h-3.6v3h-3v3.6z"
        />
    </SvgIcon>
);

export default SvgShield;
