import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgPaperPlaneSmall = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2 .01 7z" />
    </SvgIcon>
);

export default SvgPaperPlaneSmall;
