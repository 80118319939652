import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useMessageBoxStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.props.shadows.message,
        color: theme.palette.common.white,
        display: 'flex',
        padding: theme.spacing(2),
        transition: `padding ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        },
    },
    richtext: {
        ...theme.props.richtext,
        '& p:last-child': {
            marginBottom: 0,
        },
        '& p a': {
            borderBottom: `1px solid ${theme.palette.common.white}`,
            boxShadow: `inset 0 0 0 0 ${theme.palette.common.white}`,
            '&:focus, &:hover': {
                borderBottomColor: alpha(theme.palette.common.white, 0.25),
                boxShadow: `inset 0 calc(-1em - 4px) 0 0 ${alpha(
                    theme.palette.common.white,
                    0.25
                )}`,
            },
        },
    },
    alert: {
        backgroundColor: theme.palette.error.main,
    },
    icon: {
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
        width: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
        },
    },
}));

export default useMessageBoxStyles;
