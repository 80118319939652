import makeStyles from '@material-ui/core/styles/makeStyles';

const useExperimentsStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    title: {
        ...theme.props.boxTitle,
    },
    link: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: theme.spacing(2),
        '&:hover > $linkIcon, &:focus > $linkIcon': {
            color: theme.palette.secondary.main,
        },
    },
    linkText: {
        ...theme.typography.link,
        ...theme.typography.h5,
    },
    linkIcon: {
        height: theme.spacing(4),
        marginLeft: theme.spacing(0.5),
        transform: 'rotate(180deg)',
        transition: `color ${theme.transitions.duration.shorter}ms`,
        width: theme.spacing(4),
    },
    row: {
        borderBottom: 'none',
    },
    cell: {
        borderBottom: 'none',
        color: theme.palette.text.secondary,
        lineHeight: 1,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        '&:first-child': {
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
            },
        },
        '&:last-child': {
            paddingRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                paddingRight: theme.spacing(3),
            },
        },
    },
    // cellHideXS: {
    //     [theme.breakpoints.only('xs')]: {
    //         display: 'none',
    //         opacity: 0,
    //         visibility: 'hidden',
    //     },
    // },
    // cellHideSM: {
    //     [theme.breakpoints.down('sm')]: {
    //         display: 'none',
    //         opacity: 0,
    //         visibility: 'hidden',
    //     },
    // },
    table: {
        backgroundColor: theme.palette.common.white,
        border: 'none',
        boxShadow: theme.props.shadows.box,
    },
    message: {
        width: '100%',
    },
}));

export default useExperimentsStyles;
