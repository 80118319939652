// eslint-disable-next-line import/prefer-default-export
export const addCustomTypography = (themeInput) => {
    const theme = { ...themeInput };

    theme.typography.h1 = {
        fontFamily: theme.typography.fontFamilyPoppins,
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0,
        lineHeight: 46 / 32,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(42),
            lineHeight: 60 / 42,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.typography.pxToRem(56),
            lineHeight: 78 / 56,
        },
    };

    theme.typography.h2 = {
        fontFamily: theme.typography.fontFamilyPoppins,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0,
        fontSize: theme.typography.pxToRem(28),
        lineHeight: 40 / 28,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(40),
            lineHeight: 56 / 40,
        },
    };

    theme.typography.h3 = {
        fontFamily: theme.typography.fontFamilyPoppins,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0,
        lineHeight: 34 / 24,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(30),
            lineHeight: 42 / 30,
        },
    };

    theme.typography.h5 = {
        fontFamily: theme.typography.fontFamilyPoppins,
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0,
        lineHeight: 26 / 18,
    };

    theme.typography.body1 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: 0,
        lineHeight: 22 / 14,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 24 / 16,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: 26 / 18,
        },
    };

    theme.typography.body2 = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: 0,
        lineHeight: 24 / 16,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: 26 / 18,
        },
    };

    theme.typography.meta = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: 0,
        lineHeight: 14 / 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 22 / 14,
        },
    };

    theme.typography.inputError = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: 0,
        lineHeight: 14 / 12,
    };

    theme.typography.headerAdmin = {
        fontFamily: theme.typography.fontFamilyPoppins,
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0,
        lineHeight: 46 / 32,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(36),
            lineHeight: 36 / 50,
        },
    };

    theme.typography.headerSub = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: 0,
        lineHeight: 22 / 14,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 22 / 16,
        },
    };

    theme.typography.footer = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 14 / 12,
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 18 / 14,
        },
    };

    theme.typography.navLabel = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 20 / 16,
    };

    theme.typography.navItem = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: 0,
        lineHeight: 30 / 24,
    };

    theme.typography.menuLabel = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 19 / 16,
        textTransform: 'uppercase',
    };

    theme.typography.menuItem = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 29 / 24,
    };

    theme.typography.button = {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 1,
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(18),
        },
    };

    theme.typography.overline = {
        fontFamily: theme.typography.fontFamilyPoppins,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 0,
        lineHeight: 1,
    };

    // Set typography gradient clip
    theme.typography.gradient = {
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        backgroundImage: `linear-gradient(left, ${theme.palette.gradient.dark} 0%, ${theme.palette.gradient.medium} 50%, ${theme.palette.gradient.light} 100%)`,
        color: theme.palette.gradient.medium,
    };

    // Text Links
    theme.typography.link = {
        fontWeight: theme.typography.fontWeightRegular,
        position: 'relative',
        transition: `color ${theme.transitions.duration.shorter}ms ease-out`,
        whiteSpace: 'nowrap',
        '&:after': {
            backgroundColor: theme.palette.gradient.light,
            bottom: -2,
            content: '""',
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            transition: `width ${theme.transitions.duration.shorter}ms ease-out`,
            width: '0%',
        },
        'a:not[.active]:hover > &': {
            color: theme.palette.gradient.light,
        },
        'a:hover > &:after': {
            width: '100%',
        },
        'a:not[.active] > &': {
            color: theme.palette.gradient.light,
        },
        'a:focus > &:after': {
            width: '100%',
        },
        '&.active': {
            color: theme.palette.common.white,
        },
        '&.active:after': {
            backgroundColor: theme.palette.common.white,
        },
    };

    return theme;
};
