import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgHamburger = (props) => (
    <SvgIcon viewBox="0 0 32 32" {...props}>
        <defs>
            <linearGradient
                id="hamburger-gradient"
                x1=".96"
                x2="35.52"
                y1="4.16"
                y2="27.84"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#1A3869" />
                <stop offset=".50" stopColor="#0076C0" />
                <stop offset="1" stopColor="#41C0C0" />
            </linearGradient>
            <linearGradient
                id="hamburger-gradient--mid"
                x1=".96"
                x2="35.52"
                y1="4.16"
                y2="27.84"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#1A3869" />
                <stop offset=".50" stopColor="#41C0C0" />
                <stop offset="1" stopColor="#41C0C0" />
            </linearGradient>
            <path
                id="hamburger-path"
                fillRule="evenodd"
                d="M0 8.889V5.333h32V8.89H0zm0 8.889h32v-3.556H0v3.556zm0 8.889h32V23.11H0v3.556z"
            />
            <mask id="hamburger-mask" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
                <use fill="#fff" href="#hamburger-path" />
            </mask>
        </defs>
        <g mask="url(#hamburger-mask)">
            <rect width="32" height="32" fill="url(#hamburger-gradient)" rx="0" ry="0" />
            <rect
                className="transition--mid"
                fill="url(#hamburger-gradient--mid)"
                height="32"
                rx="0"
                ry="0"
                width="32"
            />
            <rect
                className="transition--full"
                fill="#41C0C0"
                height="32"
                rx="0"
                ry="0"
                width="32"
            />
        </g>
    </SvgIcon>
);

export default SvgHamburger;
