import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgCheck = (props) => (
    <SvgIcon viewBox="0 0 16 16" {...props}>
        <path d="M5.085 11.525L1.292 7.732 0 9.014 5.085 14.1 16 3.184 14.717 1.9l-9.632 9.624z" />
    </SvgIcon>
);

export default SvgCheck;
