import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgTwoPlanes = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="m3.064 4.34.957 1.277H7.66L6 0h1.404l3.32 5.617h3.83a1.28 1.28 0 0 1 1.276 1.277 1.28 1.28 0 0 1-1.277 1.276h-3.83l-3.319 5.617H6L7.66 8.17H4.02l-.957 1.277H1.787l.766-2.553-.766-2.554h1.277zm17.872 15.32-.957-1.277H16.34L18 24h-1.404l-3.32-5.617h-3.83a1.28 1.28 0 0 1-1.276-1.277 1.28 1.28 0 0 1 1.277-1.276h3.83l3.319-5.617H18l-1.66 5.617h3.639l.957-1.277h1.277l-.766 2.553.766 2.554h-1.277z"
        />
    </SvgIcon>
);

export default SvgTwoPlanes;
