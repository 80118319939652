import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

// MUI COMPONENTS
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// COMPONENTS
import Account from './Account';
import Nav from './Nav';
import Sub from './Sub';

// SVGS
import SvgHamburger from '../svgs/Hamburger';
import SvgHamburgerClose from '../svgs/HamburgerClose';
import SvgLogo from '../svgs/Logo';

// STYLES
import useHeaderStyles from '../../theme/header/header.style';

const Header = (props) => {
    const { user, time, title } = props;
    const [height, setHeight] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [scrolledDown, setScrolledDown] = React.useState(false);
    const [lastScrollY, setLastScrollY] = React.useState(0);
    const appbar = React.useRef(null);
    const classes = useHeaderStyles();

    /**
     * Toggles nav open/closed. Attaches a class to html DOM element to prevent page body from scrolling underneath when the nav is open on XS breakpoint.
     */
    const toggleNav = () => {
        const list = document.getElementsByClassName('html');
        if (list.length > 0) {
            if (list[0].classList.contains(classes.html)) {
                list[0].classList.remove(classes.html);
            } else {
                list[0].classList.add(classes.html);
            }
        }
        setOpen(!open);
    };

    /**
     * Stores the pixel height of the current header which can change depending on the length of the title string and the browser width, so the body content of the page can have an accurate top padding and the header doesn't overlap page content.
     */
    const updatePadding = useCallback(() => {
        if (appbar) {
            if (!appbar.current) return setHeight(0);
            return setHeight(appbar.current.clientHeight);
        }
        return false;
    }, [appbar]);

    /**
     * Detects whether user has scrolled up or down past delta pixels.
     */
    const checkScroll = useCallback(() => {
        const scrollDelta = 40;
        const scrollTop = window.scrollY;

        // Check user has scrolled more than delta
        if (Math.abs(lastScrollY - scrollTop) <= scrollDelta) return;

        if (scrollTop > lastScrollY && scrollTop > height + scrollDelta) {
            setScrolledDown(true);
        } else if (scrollTop < document.body.getBoundingClientRect().height) {
            setScrolledDown(false);
        }

        setLastScrollY(scrollTop);
    }, [height, lastScrollY]);

    /**
     * Debounce function.
     */
    const debounce = (fn, ms) => {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                fn.apply();
            }, ms);
        };
    };

    useEffect(() => {
        let debouncedHandleScroll;
        if (window) {
            debouncedHandleScroll = debounce(() => {
                checkScroll();
            }, 100);

            window.addEventListener('scroll', debouncedHandleScroll);
        }
        return () => {
            if (window) window.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, [height, checkScroll]);

    useEffect(() => {
        let debouncedHandleResize;
        if (window) {
            debouncedHandleResize = debounce(() => {
                updatePadding();
            }, 100);

            window.addEventListener('resize', debouncedHandleResize);
        }
        return () => {
            if (window) window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [height, updatePadding]);

    useEffect(() => {
        if (window) {
            if (height < 1) updatePadding();
        }
    }, [height, updatePadding]);

    return (
        <>
            <div
                className={classes.header}
                style={{ paddingTop: height }}
                role="presentation"
                aria-hidden="true"
            />
            <AppBar
                className={cx(classes.appbar, { active: open }, { hide: scrolledDown })}
                color="inherit"
                ref={appbar}
            >
                <Toolbar className={classes.toolbar} disableGutters variant="dense">
                    <Container className={classes.container}>
                        <Box display="flex" alignItems="flex-start">
                            <Link href="/" underline="none" className={classes.logoLink}>
                                <SvgLogo className={classes.logo} />
                                <Typography variant="srOnly">
                                    Australian Nuclear Science and Technology Organisation.
                                </Typography>
                            </Link>
                            {title.length < 23 && (
                                <Hidden xsDown>
                                    <Typography
                                        className={classes.title}
                                        component="h1"
                                        variant="h1"
                                    >
                                        {title}
                                    </Typography>
                                </Hidden>
                            )}
                            {title.length > 22 && (
                                <Hidden xsDown>
                                    <Typography
                                        className={classes.title}
                                        component="h1"
                                        variant="h2"
                                    >
                                        {title}
                                    </Typography>
                                </Hidden>
                            )}
                        </Box>
                        <Box
                            className={classes.menu}
                            display="flex"
                            justifyContent="flex-end"
                            position="relative"
                        >
                            {user && <Account user={user} open={open} />}
                            <IconButton
                                aria-label={`Toggle main site menu to ${open ? 'close' : 'open'}`}
                                className={classes.toggle}
                                disableFocusRipple
                                disableRipple
                                onClick={() => toggleNav()}
                                title={`Toggle main site menu to ${open ? 'close' : 'open'}`}
                                aria-controls="site-menu"
                                aria-expanded={open}
                            >
                                {open ? (
                                    <SvgHamburgerClose
                                        className={classes.toggleIcon}
                                        role="presentation"
                                    />
                                ) : (
                                    <SvgHamburger
                                        className={classes.toggleIcon}
                                        role="presentation"
                                    />
                                )}
                            </IconButton>
                        </Box>
                    </Container>
                </Toolbar>
                <Sub time={time} />
                <Nav open={open} />
            </AppBar>
            <Hidden smUp>
                <Container>
                    <Typography className={classes.title} variant="h1">
                        {title}
                    </Typography>
                </Container>
            </Hidden>
        </>
    );
};

Header.propTypes = {
    // Seconds left in user's browser session
    time: PropTypes.number,
    // Title of the current page
    title: PropTypes.string,
    // User object
    user: PropTypes.instanceOf(Object),
};

Header.defaultProps = {
    time: null,
    title: 'User Hub',
    user: null,
};

export default Header;
