import { alpha } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useSurveyStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.gradient.dark,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(12),
        paddingTop: theme.spacing(12),
        position: 'relative',
        zIndex: 1,
        '&:before': {
            content: '""',
            background: theme.palette.gradient.dark,
            display: 'block',
            height: '100%',
            left: '-50vw',
            position: 'absolute',
            top: 0,
            width: '100vw',
            zIndex: -1,
        },
        '&:after': {
            content: '""',
            background: theme.palette.gradient.dark,
            display: 'block',
            height: '100%',
            right: '-50vw',
            position: 'absolute',
            top: 0,
            width: '100vw',
            zIndex: -1,
        },
    },
    planeWrap: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        maxWidth: theme.spacing(75.25),
        overflow: 'hidden',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '55%',
        '&:before': {
            content: '""',
            backgroundImage: `linear-gradient(to bottom right, ${alpha(
                theme.palette.gradient.dark,
                1
            )}, ${alpha(theme.palette.gradient.dark, 0)})`,
            display: 'block',
            height: '100%',
            position: 'absolute',
            right: '0',
            top: 0,
            width: '100%',
            zIndex: 1,
        },
    },
    plane: {
        color: '#16305A',
        bottom: '50%',
        height: 'auto',
        position: 'absolute',
        right: 0,
        transform: 'translateY(50%)',
        width: '100%',
    },
    copy: {
        ...theme.props.boxTitle,
        color: theme.palette.common.white,
        marginBottom: 0,
        zIndex: 2,
        '& > p': {
            marginBottom: theme.spacing(2),
            marginTop: 0,
        },
    },
    link: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 0,
        textAlign: 'center',
        '&:hover > $linkIcon, &:focus > $linkIcon': {
            color: theme.palette.secondary.main,
            transform: 'translateX(16px)',
        },
    },
    linkText: {
        ...theme.typography.link,
        ...theme.typography.h5,
        color: theme.palette.common.white,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: theme.spacing(1),
    },
    linkIcon: {
        color: theme.palette.common.white,
        height: theme.spacing(4),
        marginLeft: theme.spacing(1),
        transform: 'translateX(0)',
        transition: `color ${theme.transitions.duration.shorter}ms, transform ${theme.transitions.duration.shorter}ms`,
        width: theme.spacing(4),
    },
}));

export default useSurveyStyles;
