import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// COMPONENTS
import MessageBox from '../elements/MessageBox';
import ExperimentsRow from './ExperimentsRow';

// SVGS
import SvgArrow from '../svgs/Arrow';

// FUNCTIONS
import { env } from '../../config';

// STYLES
import useExperimentsStyles from '../../theme/dashboard/experiments.style';

const Experiments = (props) => {
    const { customClasses, experiments, isStaff } = props;
    const classes = useExperimentsStyles();

    return (
        <div className={cx(classes.root, customClasses)}>
            <Typography className={classes.title} color="primary" variant="h3">
                Upcoming Experiments ({isStaff ? `24 Hours` : `30 Days`})
            </Typography>

            <Link
                href={`${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/experimentalAuthorisation/admin`}
                className={classes.link}
                underline="none"
            >
                <span className={classes.linkText}>See all</span>
                <SvgArrow role="presentation" className={classes.linkIcon} />
            </Link>

            {experiments.length < 1 && (
                <div className={classes.message}>
                    <MessageBox>
                        <p>You have no upcoming experiments in the next 30 days.</p>
                    </MessageBox>
                </div>
            )}

            {experiments.length > 0 && (
                <Table className={classes.table}>
                    <Hidden smDown>
                        <TableHead className={classes.head}>
                            <TableRow className={classes.row}>
                                <TableCell className={classes.cell}>
                                    <Typography variant="overline">Experiment</Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <Typography variant="overline">Contact</Typography>
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    <Typography variant="overline">Beamtime</Typography>
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    <Typography variant="overline">EA</Typography>
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    <Typography variant="overline">Beamline</Typography>
                                </TableCell>
                                <TableCell className={classes.cell} align="right">
                                    <Typography variant="overline">Travel Grant</Typography>
                                </TableCell>
                                <TableCell className={classes.cell} align="right">
                                    <Typography variant="overline">Guesthouse</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Hidden>
                    <TableBody>
                        {experiments.map((row) => (
                            <ExperimentsRow key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

Experiments.propTypes = {
    customClasses: PropTypes.string,
    experiments: PropTypes.instanceOf(Array),
    isStaff: PropTypes.bool,
};

Experiments.defaultProps = {
    customClasses: null,
    experiments: [],
    isStaff: null,
};

export default Experiments;
