import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgAirplaneTicket = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M.012 2.4H24v19.2H0v-7.2A2.4 2.4 0 0 0 2.4 12c0-1.32-1.068-2.4-2.388-2.4V2.4zm8.232 13.992 10.632-2.832c.616-.174.983-.806.828-1.428a1.16 1.16 0 0 0-1.416-.816l-2.928.78-4.812-4.488-1.68.456 2.88 4.992-2.868.768-1.512-1.188-1.116.3 1.992 3.456z"
        />
    </SvgIcon>
);

export default SvgAirplaneTicket;
