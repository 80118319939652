import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// SVGS
import SvgAirplaneTicket from '../svgs/AirplaneTicket';
import SvgArrow from '../svgs/Arrow';
import SvgBallot from '../svgs/Ballot';
import SvgBed from '../svgs/Bed';
import SvgClock from '../svgs/Clock';
import SvgFancyCloud from '../svgs/FancyCloud';
import SvgFAQ from '../svgs/FAQ';
import SvgHistory from '../svgs/History';
import SvgLaptop from '../svgs/Laptop';
import SvgMachineArm from '../svgs/MachineArm';
import SvgPublication from '../svgs/Publication';
import SvgShield from '../svgs/Shield';
import SvgTwoPlanes from '../svgs/TwoPlanes';

// FUNCTIONS
import { env } from '../../config';

// STYLES
import useQuickLinksStyles from '../../theme/dashboard/quickLinks.style';

const QuickLinks = (props) => {
    const { customClasses } = props;
    const classes = useQuickLinksStyles();

    return (
        <nav className={customClasses}>
            <ul className={classes.wrap}>
                <li>
                    <Link
                        href="https://www.ansto.gov.au/facilities/australian-synchrotron/melbourne-access-proposals"
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Upcoming Deadlines
                        </Typography>
                        <SvgClock role="presentation" className={classes.icon} />
                    </Link>
                </li>

                <li>
                    <Link
                        href="https://www.ansto.gov.au/our-facilities/australian-synchrotron/before-your-experiment"
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Experiment Preparations
                        </Typography>
                        <SvgBallot role="presentation" className={classes.icon} />
                    </Link>
                </li>
                <li>
                    <Link
                        href={`${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/proposal/admin`}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Proposals
                        </Typography>
                        <SvgHistory role="presentation" className={classes.icon} />
                    </Link>
                </li>
                <li>
                    <Link
                        href={`${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/experimentalAuthorisation/admin`}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            EAs
                        </Typography>
                        <SvgMachineArm role="presentation" className={classes.icon} />
                    </Link>
                </li>
                <li>
                    <Link
                        href={`${env.REACT_APP_DATA_STATUS_USER_UI_URL}`}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Data Status
                        </Typography>
                        <SvgFancyCloud role="presentation" className={classes.icon} />
                    </Link>
                </li>

                <li>
                    <Link
                        href={env.REACT_APP_TRAVEL_GRANT_USER_UI_URL}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Travel Grants
                        </Typography>
                        <SvgTwoPlanes role="presentation" className={classes.icon} />
                    </Link>
                </li>
                <li>
                    <Link
                        href={env.REACT_APP_ACCOM_REQUEST_USER_UI_URL}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Accommodation
                        </Typography>
                        <SvgBed role="presentation" className={classes.icon} />
                    </Link>
                </li>
                <li>
                    <Link
                        href={`${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/quizEngine/examExam/listforuser`}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Safety Inductions
                        </Typography>
                        <SvgShield role="presentation" className={classes.icon} />
                    </Link>
                </li>

                <li>
                    <Link
                        href={env.REACT_APP_VISA_DOC_USER_UI_URL}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Visa Documentation
                        </Typography>
                        <SvgAirplaneTicket role="presentation" className={classes.icon} />
                    </Link>
                </li>

                <li>
                    <Link
                        href={`${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/publication/publication/admin`}
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            Publications
                        </Typography>
                        <SvgPublication role="presentation" className={classes.icon} />
                    </Link>
                </li>

                <li>
                    <Link
                        href="https://www.ansto.gov.au/facilities/australian-synchrotron/australian-synchrotron-faqs"
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            FAQs
                        </Typography>
                        <SvgFAQ role="presentation" className={classes.icon} />
                    </Link>
                </li>

                <li>
                    <Link
                        href="https://asci.synchrotron.org.au"
                        className={classes.square}
                        underline="none"
                    >
                        <Typography variant="h5" className={classes.squareText}>
                            ASCI Desktop
                        </Typography>
                        <SvgLaptop role="presentation" className={classes.icon} />
                    </Link>
                </li>
            </ul>

            <Typography className={classes.copy} color="primary" variant="h5">
                Still haven&apos;t found what you are looking for? You might need the legacy{' '}
                <Link
                    href={`${env.REACT_APP_LEGACY_USER_PORTAL_URL}/index.php/site/dashboard`}
                    className={classes.link}
                    underline="none"
                >
                    <span className={classes.linkText}>Portal Dashboard</span>
                    <SvgArrow role="presentation" className={classes.linkIcon} />
                </Link>
            </Typography>
        </nav>
    );
};

QuickLinks.propTypes = {
    customClasses: PropTypes.string,
};

QuickLinks.defaultProps = {
    customClasses: null,
};

export default QuickLinks;
