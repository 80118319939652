import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgExclaimation = (props) => (
    <SvgIcon viewBox="0 0 32 32" {...props}>
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M15.984 0C7.152 0 0 7.168 0 16s7.152 16 15.984 16C24.832 32 32 24.832 32 16S24.832 0 15.984 0zM3.2 16c0 7.072 5.728 12.8 12.8 12.8 7.072 0 12.8-5.728 12.8-12.8 0-7.072-5.728-12.8-12.8-12.8C8.928 3.2 3.2 8.928 3.2 16zm14.4-8v9.6h-3.2V8h3.2zm0 12.8V24h-3.2v-3.2h3.2z"
        />
    </SvgIcon>
);

export default SvgExclaimation;
