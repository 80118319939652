import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgHistory = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M9.276 2.184v1.693a8.689 8.689 0 0 0-3.202-.613A8.556 8.556 0 0 0 0 5.779l4.086 4.086h1.362v1.362a5.736 5.736 0 0 0 3.816 1.669v2.785H5.595v3.681a2.461 2.461 0 0 0 2.454 2.454h12.27A3.676 3.676 0 0 0 24 18.135V2.184H9.276zM20.32 19.362a1.23 1.23 0 0 0 1.227-1.227V4.638H11.73v.76l7.362 7.326v1.73h-1.73l-3.472-3.46-.246.245a5.716 5.716 0 0 1-1.914 1.264v3.178h7.362v2.454a1.23 1.23 0 0 0 1.227 1.227zM7.914 10.049V7.411H5.117L3.84 6.135a6.22 6.22 0 0 1 2.234-.417c1.644 0 3.178.638 4.343 1.791l1.73 1.73-.245.246a3.313 3.313 0 0 1-2.356.981 3.397 3.397 0 0 1-1.632-.417z"
        />
    </SvgIcon>
);

export default SvgHistory;
