import { alpha } from '@material-ui/core/styles/colorManipulator';

// eslint-disable-next-line import/prefer-default-export
export const addModuleBox = (themeInput) => {
    const theme = { ...themeInput };

    theme.props.box = {
        backgroundColor: theme.palette.common.white,
        border: 'none',
        boxShadow: theme.props.shadows.box,
        display: 'flex',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        position: 'relative',
        width: '100%',
        transition: theme.transitions.create(['box-shadow'], {
            duration: theme.transitions.duration.shorter,
        }),
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(4),
            paddingTop: theme.spacing(3),
        },
        '&.editing': {
            boxShadow: theme.props.shadows.boxActive,
            backgroundColor: alpha(theme.palette.common.yellow, 0.05),
        },
    };

    theme.props.boxOverlay = {
        backgroundColor: alpha(theme.palette.common.white, 0.9),
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: -2,
        opacity: 0,
        transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
        }),
        '&:before': {
            background: `linear-gradient(45deg, ${theme.palette.common.white}, ${theme.palette.gradient.light}, ${theme.palette.gradient.medium}, ${theme.palette.gradient.dark}, ${theme.palette.gradient.medium}, ${theme.palette.gradient.light}, ${theme.palette.common.white})`,
            backgroundSize: '400% 400%',
            content: '""',
            display: 'flex',
            height: theme.spacing(0.5),
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
        },
    };

    theme.props.boxTitle = {
        marginBottom: theme.spacing(2),
    };

    theme.props.boxButtons = {
        ...theme.typography.h3,
        alignItems: 'center',
        display: 'inline-flex',
        marginBottom: theme.spacing(2),
        '& button + button': {
            marginLeft: theme.spacing(2),
        },
    };

    return theme;
};
