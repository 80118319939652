import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Hidden from '@material-ui/core/Hidden';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// COMPONENTS
import ButtonIcon from '../elements/ButtonIcon';

// SVGS
import SvgCheck from '../svgs/Check';
import SvgClose from '../svgs/Close';
import SvgLink from '../svgs/Link';
import SvgPlus from '../svgs/Plus';
import SvgTimer from '../svgs/Timer';

// FUNCTIONS
import { env } from '../../config';
import { formatDate, formatTime } from '../functions/utils';

// STYLES
import useExperimentsRowStyles from '../../theme/dashboard/experimentsRow.style';

const ExperimentsRow = (props) => {
    const { row } = props;
    const classes = useExperimentsRowStyles();
    let eaStatusComp = null;
    let grantEligibleComp = null;
    let grantStatusComp = null;
    let accomStatusComp = null;

    switch (row.ea_status) {
        case 'approved':
            eaStatusComp = (
                <span
                    className={cx(classes.statusIcon, classes.statusGreen, classes.statusEA)}
                    title="Status: Approved"
                    label="Status: Approved"
                >
                    <SvgCheck role="presentation" />
                </span>
            );
            break;
        case 'exists':
            eaStatusComp = (
                <span
                    className={cx(classes.statusIcon, classes.statusYellow, classes.statusEA)}
                    title="Status: Exists"
                    label="Status: Exists"
                >
                    <SvgTimer role="presentation" />
                </span>
            );
            break;
        default:
            eaStatusComp = (
                <span
                    className={cx(classes.statusIcon, classes.statusRed, classes.statusEA)}
                    title="Status: No EA"
                    label="Status: No EA"
                >
                    <SvgClose role="presentation" />
                </span>
            );
    }

    switch (row.grant_eligible) {
        case true:
            grantEligibleComp = (
                <ButtonIcon
                    href={`${env.REACT_APP_TRAVEL_GRANT_USER_UI_URL}/notice`}
                    title="Create Travel Grant Application"
                    label="Create Travel Grant Application"
                    customClasses={classes.buttonCreate}
                >
                    <SvgPlus role="presentation" />
                </ButtonIcon>
            );
            break;
        default:
            grantEligibleComp = (
                <div className={cx(classes.application, classes.applicationNone)}>
                    <span
                        className={cx(classes.statusIcon, classes.statusRed)}
                        title="Your facility is not eligible for Travel Grants"
                        label="Your facility is not eligible for Travel Grants"
                    >
                        <SvgClose role="presentation" />
                    </span>
                </div>
            );
    }

    if (row.grant_exists) {
        switch (row.grant_exists.status) {
            case 'declined':
                grantStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusRed)}
                        title="Status: Declined"
                        label="Status: Declined"
                    >
                        <SvgClose role="presentation" />
                    </span>
                );
                break;
            case 'approved':
                grantStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusGreen)}
                        title="Status: Approved"
                        label="Status: Approved"
                    >
                        <SvgCheck role="presentation" />
                    </span>
                );
                break;
            case 'awaiting_approval':
                grantStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusYellow)}
                        title="Status: Pending Approval"
                        label="Status: Pending Approval"
                    >
                        <SvgTimer role="presentation" />
                    </span>
                );
                break;
            case 'in_progress':
                grantStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusGrey)}
                        title="Status: Draft"
                        label="Status: Draft"
                    />
                );
                break;
            default:
                grantStatusComp = null;
        }
    }

    if (row.accom_request_exists) {
        switch (row.accom_request_exists.status) {
            case 'declined':
                accomStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusRed)}
                        title="Status: Declined"
                        label="Status: Declined"
                    >
                        <SvgClose role="presentation" />
                    </span>
                );
                break;
            case 'approved':
                accomStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusGreen)}
                        title="Status: Approved"
                        label="Status: Approved"
                    >
                        <SvgCheck role="presentation" />
                    </span>
                );
                break;
            case 'awaiting_approval':
                accomStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusYellow)}
                        title="Status: Pending Approval"
                        label="Status: Pending Approval"
                    >
                        <SvgTimer role="presentation" />
                    </span>
                );
                break;
            case 'in_progress':
                accomStatusComp = (
                    <span
                        className={cx(classes.statusIcon, classes.statusGrey)}
                        title="Status: Draft"
                        label="Status: Draft"
                    />
                );
                break;
            default:
                accomStatusComp = null;
        }
    }

    return (
        <TableRow className={classes.row}>
            <TableCell className={cx(classes.cell, classes.cellName)} scope="row">
                <Hidden mdUp>
                    <p className={classes.cellSMLabel}>
                        <b>Experiment</b>
                    </p>
                </Hidden>
                <span className={classes.expText}>{row.name}</span>
            </TableCell>
            <TableCell className={cx(classes.cell, classes.cellContact, classes.cellSMLeft)}>
                <Hidden mdUp>
                    <p className={classes.cellSMLabel}>
                        <b>Contact</b>
                    </p>
                </Hidden>
                {row.contact ? (
                    <Typography className={classes.contactLink} variant="body1">
                        <div className={classes.avatar}>
                            {row.contact.avatar_url && (
                                <img
                                    src={row.contact.avatar_url}
                                    role="presentation"
                                    aria-hidden="true"
                                    alt={`Profile image for ${row.contact.name}`}
                                />
                            )}
                        </div>
                        <span className={classes.contactText}>
                            {row.contact ? row.contact.name : '-'}
                        </span>
                    </Typography>
                ) : (
                    <p>
                        <i>No Contact</i>
                    </p>
                )}
            </TableCell>
            <TableCell
                className={cx(classes.cell, classes.cellBeamtime, classes.cellSMMiddle)}
                align="center"
            >
                <Hidden mdUp>
                    <p className={classes.cellSMLabel}>
                        <b>Beamtime</b>
                    </p>
                </Hidden>
                <p>
                    <span className={classes.startDatetime}>
                        {formatDate(row.start_datetime) ? formatDate(row.start_datetime) : '-'}
                    </span>
                    <span className={classes.startDatetime}>
                        {formatTime(row.start_datetime) ? formatTime(row.start_datetime) : '-'}
                    </span>
                    {row.start_datetime !== row.end_datetime && row.end_datetime && (
                        <span className={classes.endDatetime}>
                            {formatDate(row.end_datetime) ? formatDate(row.end_datetime) : '-'}
                        </span>
                    )}
                    <span className={classes.endDatetime}>
                        {formatTime(row.end_datetime) ? formatTime(row.end_datetime) : '-'}
                    </span>
                </p>
            </TableCell>
            <TableCell
                className={cx(classes.cell, classes.cellEA, classes.cellSMRight)}
                align="center"
            >
                <Hidden mdUp>
                    <p className={classes.cellSMLabel}>
                        <b>EA</b>
                    </p>
                </Hidden>
                {eaStatusComp}
            </TableCell>
            <TableCell
                className={cx(classes.cell, classes.cellBeamline, classes.cellSMLeft)}
                align="center"
            >
                <Hidden mdUp>
                    <p className={classes.cellSMLabel}>
                        <b>Beamline</b>
                    </p>
                </Hidden>
                <p>{row.beamline}</p>
            </TableCell>
            <TableCell className={cx(classes.cell, classes.cellSMMiddle)} align="right">
                <Hidden mdUp>
                    <p className={classes.cellSMLabel}>
                        <b>Travel Grant</b>
                    </p>
                </Hidden>
                {row.grant_exists && (
                    <div className={classes.application}>
                        {grantStatusComp}
                        <ButtonIcon
                            href={`${env.REACT_APP_TRAVEL_GRANT_USER_UI_URL}/${row.grant_exists.id}`}
                            title="View Travel Grant Application"
                            label="View Travel Grant Application"
                        >
                            <SvgLink role="presentation" />
                        </ButtonIcon>
                    </div>
                )}
                {!row.grant_exists && grantEligibleComp}
            </TableCell>
            <TableCell className={cx(classes.cell, classes.cellSMRight)} align="right">
                <Hidden mdUp>
                    <p className={classes.cellSMLabel}>
                        <b>Guesthouse</b>
                    </p>
                </Hidden>
                {row.accom_request_exists ? (
                    <div className={classes.application}>
                        {accomStatusComp}
                        <ButtonIcon
                            href={`${env.REACT_APP_ACCOM_REQUEST_USER_UI_URL}/${row.accom_request_exists.id}`}
                            title="View Accommodation Request Application"
                            label="View Accommodation Request Application"
                        >
                            <SvgLink role="presentation" />
                        </ButtonIcon>
                    </div>
                ) : (
                    <ButtonIcon
                        href={`${env.REACT_APP_ACCOM_REQUEST_USER_UI_URL}/notice`}
                        title="Create Accommodation Request Application"
                        label="Create Accommodation Request Application"
                        customClasses={classes.buttonCreate}
                    >
                        <SvgPlus role="presentation" />
                    </ButtonIcon>
                )}
            </TableCell>
        </TableRow>
    );
};

ExperimentsRow.propTypes = {
    row: PropTypes.instanceOf(Object),
};

ExperimentsRow.defaultProps = {
    row: null,
};

export default ExperimentsRow;
