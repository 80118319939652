import { alpha } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useApplicationsRowStyles = makeStyles((theme) => ({
    row: {
        borderLeft: 0,
        borderRight: 0,
        borderTop: `1px solid ${alpha(theme.palette.gradient.medium, 0.1)}`,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    },
    statusIcon: {
        alignItems: 'center',
        borderRadius: theme.shape.borderCircle,
        display: 'flex',
        height: theme.spacing(3),
        justifyContent: 'center',
        marginRight: theme.spacing(1),
        width: theme.spacing(3),
        '& > svg': {
            fill: theme.palette.common.white,
            height: theme.spacing(2),
            width: theme.spacing(2),
        },
    },
    statusRed: {
        backgroundColor: theme.palette.error.main,
    },
    statusGrey: {
        backgroundColor: theme.palette.common.grey,
    },
    statusYellow: {
        backgroundColor: theme.palette.common.yellow,
    },
    statusGreen: {
        backgroundColor: theme.palette.common.green,
    },
    cell: {
        borderBottom: 0,
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1.5),
        verticalAlign: 'top',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        '&:first-child': {
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(3),
            },
        },
        '&:last-child': {
            paddingRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                paddingRight: theme.spacing(3),
            },
        },
        '& p': {
            ...theme.typography.headerSub,
            margin: 0,
            lineHeight: 24 / 14,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.pxToRem(16),
                lineHeight: 26 / 16,
            },
        },
    },
    cellHideSM: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
            opacity: 0,
            visibility: 'hidden',
        },
    },
    cellSMLeft: {
        '&$cell': {
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                borderBottom: 'none',
                flexDirection: 'column',
                minWidth: '20%',
                paddingLeft: theme.spacing(2),
                paddingTop: 0,
                textAlign: 'left',
                width: '20%',
            },
            [theme.breakpoints.down('xs')]: {
                minWidth: '33.33%',
                width: '33.33%',
            },
        },
    },
    cellSMMiddle: {
        '&$cell': {
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-end',
                borderBottom: 'none',
                flexDirection: 'column',
                minWidth: '20%',
                paddingRight: theme.spacing(1),
                paddingTop: 0,
                textAlign: 'right',
                width: '20%',
            },
            [theme.breakpoints.down('xs')]: {
                minWidth: '33.33%',
                width: '33.33%',
            },
        },
    },
    cellSMRight: {
        '&$cell': {
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-end',
                borderBottom: 'none',
                flexDirection: 'column',
                minWidth: '20%',
                paddingRight: theme.spacing(2),
                paddingTop: 0,
                textAlign: 'right',
                width: '20%',
            },
            [theme.breakpoints.down('xs')]: {
                minWidth: '33.33%',
                width: '33.33%',
            },
        },
    },
    cellSMLabel: {
        marginTop: theme.spacing(0.5),
    },
    cellName: {
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '66.66%',
            minWidth: '66.66%',
            width: '66.66%',
        },
        [theme.breakpoints.between('960', '1200')]: {
            maxWidth: theme.spacing(28.5),
            minWidth: theme.spacing(28.5),
            width: theme.spacing(28.5),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.spacing(53.375),
            minWidth: theme.spacing(53.375),
            width: theme.spacing(53.375),
        },
    },
    expText: {
        fontWeight: theme.typography.fontWeightRegular,
        whiteSpace: 'normal',
    },
    cellContact: {
        alignItems: 'center',
        borderBottom: 0,
        display: 'flex',
        position: 'relative',
        width: '100%',
        [theme.breakpoints.only('sm')]: {
            paddingRight: theme.spacing(2),
            '&$cellSMLeft': {
                minWidth: '33.33%',
                paddingTop: theme.spacing(1.5),
                width: '33.33%',
            },
        },
    },
    contactLink: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: theme.spacing(23),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: theme.spacing(23),
    },
    contactText: {
        ...theme.typography.link,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    avatar: {
        backgroundColor: theme.palette.background.avatar,
        borderRadius: theme.shape.borderCircle,
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
        minWidth: theme.spacing(4),
        overflow: 'hidden',
        width: theme.spacing(4),
        '& > img, & > svg': {
            height: theme.spacing(4),
            width: theme.spacing(4),
        },
        '& > svg': {
            color: theme.palette.common.white,
        },
    },
    cellBeamtime: {
        '&$cellSMMiddle': {
            [theme.breakpoints.up('sm')]: {
                textAlign: 'left',
                paddingLeft: theme.spacing(2),
            },
        },
    },
    startDatetime: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: theme.typography.fontWeightRegular,
        justifyContent: 'center',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
        '& > &': {
            marginTop: '2px',
        },
        '& + $endDatetime': {
            marginTop: '8px',
        },
        '& + $endDatetime:before': {
            backgroundColor: theme.palette.action.disabled,
            content: '""',
            display: 'block',
            height: '1px',
            left: '50%',
            position: 'absolute',
            top: '-4px',
            transform: 'translateX(-50%)',
            width: theme.spacing(1.5),
            [theme.breakpoints.only('xs')]: {
                left: '75%',
            },
            [theme.breakpoints.up('sm')]: {
                left: '25%',
            },
        },
    },
    endDatetime: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: theme.typography.fontWeightRegular,
        justifyContent: 'center',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
        '& > &': {
            marginTop: '2px',
        },
    },
    cellEA: {
        '&$cellSMRight': {
            [theme.breakpoints.up('sm')]: {
                textAlign: 'center',
            },
        },
    },
    statusEA: {
        marginLeft: 'auto',
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginRight: 'auto',
        },
    },
    cellBeamline: {
        '&$cellSMLeft': {
            [theme.breakpoints.up('sm')]: {
                textAlign: 'right',
            },
        },
    },
    application: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    applicationNone: {
        marginRight: theme.spacing(1),
    },
    buttonCreate: {
        marginRight: theme.spacing(2),
    },
}));

export default useApplicationsRowStyles;
