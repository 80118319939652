import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgClose = (props) => (
    <SvgIcon viewBox="0 0 16 16" {...props}>
        <path d="M16 1.611L14.389 0 8 6.389 1.611 0 0 1.611 6.389 8 0 14.389 1.611 16 8 9.611 14.389 16 16 14.389 9.611 8 16 1.611z" />
    </SvgIcon>
);

export default SvgClose;
