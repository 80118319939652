import React, { useCallback, useEffect, useState } from 'react';

// MUI COMPONENTS
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// COMPONENTS
import Contact from '../components/dashboard/Contact';
import Experiments from '../components/dashboard/Experiments';
import QuickLinks from '../components/dashboard/QuickLinks';
import Survey from '../components/dashboard/Survey';
import AxiosErrors from '../components/elements/AxiosErrors';
import LoadingIcon from '../components/elements/LoadingIcon';
import MessageBox from '../components/elements/MessageBox';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

// FUNCTIONS
import useAxios from '../components/functions/useAxios';
import { formatBFFPostPutErrors } from '../components/functions/utils';
import { env } from '../config';

// STYLES
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    section: {
        '& + &': {
            marginTop: theme.spacing(10),
        },
        '& + $largeSpacer': {
            marginTop: theme.spacing(10),
        },
        '&:last-child': {
            marginBottom: theme.spacing(10),
        },
    },
    loading: {
        height: theme.spacing(30),
        marginTop: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(4),
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(6),
        },
        '& > span': {
            fontFamily: theme.typography.fontFamilyPoppins,
            fontWeight: theme.typography.fontWeightBold,
            marginTop: theme.spacing(2),
        },
    },
}));

export default () => {
    const classes = useStyles();
    const [errMsgs, setErrMsgs] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const bffAxios = useAxios(`${env.REACT_APP_DASHBOARD_USER_BFF_URL}`);
    const [user, setUser] = useState({});
    const [experiments, setExperiments] = useState(null);
    const [loadingMsg, setLoadingMsg] = useState(null);

    const getUser = useCallback(async () => {
        if (bffAxios) {
            bffAxios
                .get(`/user/current`)
                .then((res) => {
                    setUser(res.data);
                })
                .catch((error) => {
                    const errs = formatBFFPostPutErrors(error);
                    setErrMsgs(errs);
                });
        }
    }, [bffAxios]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    const getExperiments = useCallback(async () => {
        if (bffAxios && Object.keys(user).length > 0) {
            const d = `/experiments${user.is_staff ? '?upcoming_days=1' : ''}`;

            bffAxios
                .get(d)
                .then((res) => {
                    setExperiments(res.data.experiments);
                })
                .catch((error) => {
                    const errs = formatBFFPostPutErrors(error);
                    setErrMsgs(errs);
                });
        }
    }, [bffAxios, user]);

    useEffect(() => {
        getExperiments();
    }, [getExperiments, user]);

    useEffect(() => {
        if (experiments !== null) setLoaded(true);
    }, [experiments]);

    useEffect(() => {
        const intervalFirst = setTimeout(() => {
            setLoadingMsg(
                `Please wait, gathering your upcoming experiments from the Science Doughnut...`
            );
        }, 7000);

        const intervalSecond = setTimeout(() => {
            setLoadingMsg(`Please wait, putting your sprinkles on the Science Doughnut...`);
        }, 14000);

        return () => {
            clearTimeout(intervalFirst);
            clearTimeout(intervalSecond);
        };
    }, []);

    return (
        <>
            <a
                href="#skip-to-main"
                className="skip-to-main-link"
                aria-label="skip to main content"
            >
                Skip to main content
            </a>
            <Header user={user} title="User Hub" />
            <Container id="skip-to-main">
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid className={classes.main} component="main" item xs={12}>
                        {errMsgs.length > 0 && (
                            <AxiosErrors
                                errors={errMsgs}
                                renderType="page"
                                customClasses={classes.section}
                            />
                        )}
                        {user.password_expiring && (
                            <MessageBox customClasses={classes.section}>
                                <p>
                                    Your password is expiring in {user.password_expiring} days. You
                                    can change your password from your{' '}
                                    <a href={env.REACT_APP_ACCOUNT_USER_UI_URL}>
                                        <span>profile page</span>
                                    </a>
                                    .
                                </p>
                            </MessageBox>
                        )}
                        <QuickLinks customClasses={classes.section} />
                        {loaded && (
                            <Experiments
                                customClasses={classes.section}
                                experiments={experiments}
                                isStaff={user.is_staff}
                            />
                        )}
                        {!loaded && (
                            <Box
                                className={classes.loading}
                                alignItems="center"
                                color="grey.30"
                                display="flex"
                                flexDirection="column"
                                justifyContent="flexStart"
                                textAlign="center"
                            >
                                <LoadingIcon />
                                {loadingMsg && (
                                    <Typography variant="body1" component="span">
                                        {loadingMsg}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        {<Survey customClasses={classes.section} />}
                        {<Contact customClasses={classes.section} />}
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
};
