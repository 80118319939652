import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgBed = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path d="M10.91 6H24v14.182h-2.182v-3.273H2.182v3.273H0V3.818h2.182v9.818h8.727V6zM9.817 9.273a3.268 3.268 0 0 1-3.273 3.272 3.268 3.268 0 0 1-3.272-3.272A3.268 3.268 0 0 1 6.545 6a3.268 3.268 0 0 1 3.273 3.273z" />
    </SvgIcon>
);

export default SvgBed;
