import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgLifesaver = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M0 12C0 5.376 5.376 0 12 0s12 5.376 12 12-5.376 12-12 12S0 18.624 0 12zm9.96-5.616L8.556 3.048a9.58 9.58 0 0 0-5.508 5.508l3.336 1.392A6 6 0 0 1 9.96 6.384zm7.656 3.54 3.336-1.38a9.555 9.555 0 0 0-5.496-5.484l-1.38 3.336a5.978 5.978 0 0 1 3.54 3.528zM12 15.6c-1.992 0-3.6-1.608-3.6-3.6s1.608-3.6 3.6-3.6 3.6 1.608 3.6 3.6-1.608 3.6-3.6 3.6zm2.076 2.016 1.38 3.336a9.608 9.608 0 0 0 5.496-5.496l-3.336-1.404a5.974 5.974 0 0 1-3.54 3.564zM3.048 15.444l3.336-1.38a5.961 5.961 0 0 0 3.564 3.552l-1.404 3.336a9.595 9.595 0 0 1-5.496-5.508z"
        />
    </SvgIcon>
);

export default SvgLifesaver;
