import makeStyles from '@material-ui/core/styles/makeStyles';

const useLoadingIconStyles = makeStyles((theme) => ({
    svg: {
        display: 'block',
        height: 'auto',
        marginBottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(7),
        width: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(6),
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(4),
        },
        '& .loading-blue': {
            animationDuration: '900ms',
            animationIterationCount: 'infinite',
            animationName: '$blinker',
            animationTimingFunction: 'linear',
            opacity: 0,
        },
        '& .loading-seafoam': {
            animationDelay: '300ms',
            animationDuration: '900ms',
            animationIterationCount: 'infinite',
            animationName: '$blinker',
            animationTimingFunction: 'linear',
            opacity: 0,
        },
        '& .loading-flag': {
            animationDelay: '600ms',
            animationDuration: '900ms',
            animationIterationCount: 'infinite',
            animationName: '$blinker',
            animationTimingFunction: 'linear',
            opacity: 0,
        },
    },
    '@keyframes blinker': {
        '0%': {
            opacity: 0,
        },
        '33%': {
            opacity: 1,
        },
        '66%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },
    },
}));

export default useLoadingIconStyles;
