import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgPlus = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path d="M10.286 0v10.286H0v3.428h10.286V24h3.428V13.714H24v-3.428H13.714V0h-3.428z" />
    </SvgIcon>
);

export default SvgPlus;
