import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgFAQ = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M12 0C6.372 0 1.8 4.572 1.8 10.2c0 5.628 4.572 10.2 10.2 10.2h.6V24c5.832-2.808 9.6-8.4 9.6-13.8C22.2 4.572 17.628 0 12 0zm2.4 7.2c0 2.4-3.6 2.1-3.6 6h2.4c0-2.7 3.6-3 3.6-6 0-2.652-2.148-4.8-4.8-4.8a4.799 4.799 0 0 0-4.8 4.8h2.4c0-1.32 1.08-2.4 2.4-2.4s2.4 1.08 2.4 2.4zm-3.6 10.2V15h2.4v2.4h-2.4z"
        />
    </SvgIcon>
);

export default SvgFAQ;
