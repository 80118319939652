import makeStyles from '@material-ui/core/styles/makeStyles';

const useButtonIconStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: theme.spacing(3),
        padding: 0,
        transition: `color ${theme.transitions.duration.shorter}ms`,
        verticalAlign: 'middle',
        width: theme.spacing(3),
        '&:hover, &:focus': {
            color: theme.palette.secondary.main,
            backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root': {
            height: theme.spacing(3),
            width: theme.spacing(3),
        },
    },
    alignSuper: {
        verticalAlign: 'super',
    },
    disabled: {
        cursor: 'default',
        '& svg': {
            color: theme.palette.action.disabled,
        },
    },
}));

export default useButtonIconStyles;
