import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgFancyCloud = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M12 6a7.49 7.49 0 0 1 7.35 6.04c2.6.18 4.65 2.32 4.65 4.96 0 2.76-2.24 5-5 5H6a5.996 5.996 0 0 1-.64-11.96A7.48 7.48 0 0 1 12 6zM6 20h13c1.65 0 3-1.35 3-3s-1.35-3-3-3h-1.5v-.5c0-3.03-2.47-5.5-5.5-5.5-1.8 0-3.39.88-4.4 2.22 2.54.7 4.4 3.02 4.4 5.78h-2c0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4z"
        />
    </SvgIcon>
);

export default SvgFancyCloud;
