import React from 'react';

// SVGS
import LoadingSvg from '../svgs/Loading';

// STYLES
import useLoadingIconStyles from '../../theme/elements/loadingIcon.style';

const LoadingIcon = () => {
    const classes = useLoadingIconStyles();

    return <LoadingSvg viewBox="0 0 46 38" className={classes.svg} />;
};

export default LoadingIcon;
