import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// MUI COMPONENTS
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// SVGS
import SvgPaperPlane from '../svgs/PaperPlane';
import SvgPaperPlaneSmall from '../svgs/PaperPlaneSmall';

// STYLES
import useSurveyStyles from '../../theme/dashboard/survey.style';

const Survey = (props) => {
    const { customClasses } = props;
    const classes = useSurveyStyles();

    return (
        <div className={cx(classes.root, customClasses)}>
            <Typography className={classes.copy} variant="h3" component="div" align="center">
                <p>Have feedback?</p>
                <Link
                    href="https://survey.synchrotron.org.au"
                    className={classes.link}
                    underline="none"
                >
                    <span className={classes.linkText}>
                        Leave us a word on our User Satisfaction Survey
                    </span>
                    <SvgPaperPlaneSmall role="presentation" className={classes.linkIcon} />
                </Link>
            </Typography>

            <div className={classes.planeWrap} role="presentation" aria-hidden>
                <SvgPaperPlane role="presentation" className={classes.plane} />
            </div>
        </div>
    );
};

Survey.propTypes = {
    customClasses: PropTypes.string,
};

Survey.defaultProps = {
    customClasses: null,
};

export default Survey;
