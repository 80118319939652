import React from 'react';

// MUI COMPONENTS
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgBallot = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M0 24h24V0H0v24zm4-13.333h6.667V4H4v6.667zm5.333-5.334h-4v4h4v-4zm4 3.334H20V6h-6.667v2.667zM4 20h6.667v-6.667H4V20zm5.333-5.333h-4v4h4v-4zm4 3.333H20v-2.667h-6.667V18z"
        />
    </SvgIcon>
);

export default SvgBallot;
